import {
  DimensionValueTypes,
  ProductForm,
  ProductValidationErrors,
  ValidationType,
  Value,
} from './types'

const mapValidationTypeToMaxDimLength: Record<ValidationType, number> = {
  base: 60,
  vas: 60,
}
const mapValidationTypeToMaxSize: Record<ValidationType, number> = {
  base: 100,
  vas: 75,
}

const validatePositive = (value: Value) => value && Number(value) > 0

const validateDimension = (dim: Value, validationType: ValidationType) => {
  if (!dim) {
    return true
  }

  const max = mapValidationTypeToMaxDimLength[validationType]

  return Number(dim) < max && validatePositive(dim)
}

const validateSize = (dim1: Value, dim2: Value, dim3: Value, validationType: ValidationType) => {
  if (!dim1 || !dim2 || !dim3) {
    return true
  }

  const numericDim1 = Number(dim1)
  const numericDim2 = Number(dim2)
  const numericDim3 = Number(dim3)

  const max = mapValidationTypeToMaxSize[validationType]

  return (numericDim1 * numericDim2 * numericDim3) / 139 < max
}

export const validateProduct = ({
  category,
  dimValue,
  vas,
}: ProductForm): ProductValidationErrors => {
  let dimensionsError = null
  let vasDimensionsError = null
  let nonCalculatable = false

  if (!category) {
    const notPositiveDimension =
      !validatePositive(dimValue[DimensionValueTypes.L]) ||
      !validatePositive(dimValue[DimensionValueTypes.W]) ||
      !validatePositive(dimValue[DimensionValueTypes.H])

    return {
      dimensionsError: notPositiveDimension ? 'Every side must be more than 0.' : null,
      vasDimensionsError,
      nonCalculatable,
    }
  }

  if (
    !validateDimension(dimValue[DimensionValueTypes.L], 'base') ||
    !validateDimension(dimValue[DimensionValueTypes.W], 'base') ||
    !validateDimension(dimValue[DimensionValueTypes.H], 'base')
  ) {
    dimensionsError = 'Side length must be in range 1-60 inches.'
    nonCalculatable = true
  }

  if (
    !validateSize(
      dimValue[DimensionValueTypes.L],
      dimValue[DimensionValueTypes.W],
      dimValue[DimensionValueTypes.H],
      'base',
    )
  ) {
    dimensionsError = 'Your product is oversize.'
    nonCalculatable = true
  }

  // validate value-add services if checked
  if (vas) {
    if (
      !validateSize(
        dimValue[DimensionValueTypes.L],
        dimValue[DimensionValueTypes.W],
        dimValue[DimensionValueTypes.H],
        'vas',
      )
    ) {
      vasDimensionsError = 'Your product is oversize.'
      nonCalculatable = true
    }
  }

  return {
    dimensionsError,
    vasDimensionsError,
    nonCalculatable,
  }
}
