export enum DimensionValueTypes {
  L = 'length',
  W = 'width',
  H = 'height',
}

export type Value = null | string

export type ProductForm = {
  category: number | undefined
  dimValue: {
    [DimensionValueTypes.L]: Value
    [DimensionValueTypes.W]: Value
    [DimensionValueTypes.H]: Value
  }
  vas: boolean
}

export type ProductValidationErrors = {
  dimensionsError: string | null
  vasDimensionsError: string | null
  nonCalculatable: boolean
}
export type ValidationType = 'base' | 'vas'

export type ProductFeesSectionConfig = {
  storage: boolean
  recievingAndPutaway: boolean
  fulfillment: boolean
  valueAddServices: boolean
}

export enum PeriodTypes {
  ninety_days = 'ninety_days',
  ninety_days_peak = 'ninety_days_peak',
}
