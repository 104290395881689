import cn from 'classnames'
import { Link } from 'gatsby'
import React from 'react'
import { getButtonClassNames } from '../../helpers'
import { ButtonLinkProps } from './types'

export const ButtonLink = ({
  to,
  className,
  size = 'default',
  variant = 'primary',
  children,
}: ButtonLinkProps) => {
  return (
    <Link to={to} className={cn('inline-block', getButtonClassNames(variant, size), className)}>
      {children}
    </Link>
  )
}
